import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    Challenge: 'Challenge',
    AddressPonit: 'How Raindrop Adds Value',
    icon: <FaArrowCircleRight />,
    HeadingOne: 'Spend Management',
    HeadingOneDen:
      'you haven’t yet acquired, or do not currently have the necessary tools required to manage your spend.',
    paraOne:
      'Raindrop provides all the functionality needed to manage your spend while fulfilling your procurement business processes.',
    subParaOne: 'One single system which scales as your enterprise grows',
    subParaTwo: 'Provides all the necessary functionality as you scale up your business',
    HeadingTwo: 'Risk and Compliance',
    HeadingTwoDen:
      'You want a solution which minimizes the change management required for your users to adhere to your procurement processes and policies.',
    paraTwo:
      'Raindrop provides the visibility and tools required to increase adoption of your procurement processes and minimize risk by--',
    subPointFour:
      'Providing a user experience needed for you to do your business your way, all centered on increasing user adoption',
    subPonitFive: 'Providing the tools needed to address your compliance issues while minimizing risk.',
    subPonitSix: 'Meeting all of your information security requirements.',

    HeadingThree: 'Solution Fit',
    HeadingThreeDen:
      'most existing solutions do not cater to mid-market needs.  Traditional Procurement software solutions are costly, take a long time to implement and maintain, and are challenged with user adoption.',
    paraThree:
      'Raindrop is designed to address the usability, cost, and time to value challenges faced by mid-market companies by –',
    subParaSeven: 'Enabling you to realize value instantly via rapid deployment',
    subParaEight: 'A simple and easy to use interface requiring minimal training, increasing user adoption',
    subParaNine: 'Freeing up time maintaining systems to focus on your core business activities',
  },
]
