import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import SolutionMidMarketBannerData from '../Data/SolutionMidMarketBannerData.json'
import { Button } from './Button'
import { AppMidmarketTable } from './AppMidmarketTable'
import { LinkHubspot } from './LinkHubspot'

const AppMidmarketBannerWapper = styled.div`
  .MidmarketContain {
    max-width: 1920px;
    margin: auto;
    padding-right: 5rem;
    @media (max-width: 1090px) {
      padding-right: unset;
    }
  }
  .MidmarketSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .MidmarketHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .MidmarketHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .midMarketParaOne {
    max-width: 825px;
    color: #047cc2;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 15px 35px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .MidmarketPara {
    max-width: 825px;
    padding: 0px 35px 15px 0px;

    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
    }
  }
  .MidmarketDownPara {
    max-width: 825px;
    color: #047cc2;
    padding: 2rem 35px 0px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .MidmarketImage {
    height: auto;
    padding-left: 5rem;
    @media (max-width: 1090px) {
      margin: auto;
      padding-left: unset;
    }
  }
  .MediumImageCenter {
    height: auto;
    width: 75%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .paraSection {
    margin-top: 3rem;
    @media (max-width: 1090px) {
      margin-top: auto;
      margin: auto;
    }
  }
  .iconColor {
    display: none;
  }
  .buttonDisplay {
    display: none;
  }
  .FreeButton {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .MidmarketDisplay {
    display: none;
  }
`

export const AppMidmarketBanner = () => {
  return (
    <AppMidmarketBannerWapper>
      {SolutionMidMarketBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              mainContain="MidmarketContain"
              containHeading="MidmarketHeadingSmall"
              containHeadingBig="MidmarketHeadingBig"
              containParaOne="midMarketParaOne"
              containParaTwo="MidmarketPara"
              containParaFour="MidmarketDisplay"
              containParaFive="MidmarketDisplay"
              containParaThree="MidmarketDownPara"
              InternalSection="MidmarketSection"
              containImage="MidmarketImage"
              altName={data.AltName}
              paraSection="paraSection"
              IconColor="iconColor"
              ButtonDisplay="buttonDisplay"
              allImage="MediumImageCenter"
            />
          </div>
        )
      })}
      <div>
        <AppMidmarketTable />
      </div>
      <div className="FreeButton">
        <LinkHubspot to="https://share.hsforms.com/1wzfrY5DmSsa2666ecHtWvw4702y">
          <span className="sr-only">Jump to packages</span>
          <Button
            cta="Click here to get started."
            label="Click here to get started."
            type="submit"
            className="demobutton"
          />
        </LinkHubspot>
      </div>
    </AppMidmarketBannerWapper>
  )
}
