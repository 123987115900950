import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Heading: 'Transforming the Outflow of Your Investments',
    paraOne: 'Fully integrated end to end platform allowing you to do business your way.',
    icon: <FaArrowCircleRight />,
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/SolutionpageBanner.png',
    ALtName: 'Intelligent spend management solution',
    LiPonitOne: 'By Company Size',
    LiPonitTwo: 'By Roles',
    LiPonitThree: 'Modules',
    // LiPonitFour: 'Contracts',
  },
]
