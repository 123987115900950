import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    icon: <FaArrowCircleRight />,
    Challenge: 'Challenge',
    AddressPonit: 'How Raindrop Adds Value',
    HeadingOne: 'Spend Visibility, Planning, and Operations',
    HeadingOneDen:
      'Data may be fragmented across multiple systems and regions, and not providing the needed information for planning.',
    paraOne: 'Raindrop enables you to plan by providing a unified view into your global enterprise spend –',
    subParaOne: 'To plan various scenarios such as mergers & acquisitions, annual operating plan, growth, and more.',
    subParaTwo:
      'By providing visibility into commodity consumption, recover costs from under-utilized commodities, and contain costs prior to over-consumption.',
    subParaThree:
      'By providing predictive capabilities via artificial intelligence (AI) and machine learning (ML) to reduce your overall spend.',
    HeadingTwo: 'Cost of ownership',
    HeadingTwoDen:
      ' Traditional Procurement tools are complex, not easy to integrate to existing systems, and are labor intensive to implement and maintain.',
    paraTwo: 'With Raindrop, customers can –',
    subPointFour: 'Replace and/or consolidate their legacy systems resulting in reduced technology cost of ownership.',
    subPonitFive: 'Replace labor costs with technology system capabilities',
    subPonitSix: 'Spend more time on business activities rather than dealing with system issues',

    HeadingThree: 'Functionality, Usability, and Adoption',
    HeadingThreeDen:
      'Traditional systems are transactionally focused, resulting in limited adoption, and potential for spend leakage between multiple systems used to fulfill business processes.',
    paraThree: 'Raindrop delivers futuristic capabilities to your enterprise by –',
    subParaSeven: 'Providing market intelligence on commodity acquisition cost',
    subParaEight: 'Providing templates to manage specific commodities – Contingent Workforce, SaaS, etc.',
    subParaNine:
      'Digitizing your procurement data which enables spend leakage – e.g., surprises from automatic contract renewals.',
    subParaTen:
      'Providing an easy-to-use and frictionless user experience which in turn results in enterprise-wide adoption.',
  },
]
