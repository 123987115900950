import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    challengesPointOne: 'Challenge',
    challengesPointTwo: 'How Raindrop Adds Value',
    HeadingOne: 'Time to value',
    HeadingOneDen: 'You’re looking for a solution which is easy to implement and costs less.',
    paraOne:
      'Raindrop can be set up in a matter of minutes to perform all of your strategic and tactical Procurement processes and delivers more value for your investment.',
    HeadingTwo: 'Scalability',
    HeadingTwoDen: 'You’re looking for a solution which works today as well as enables your company’s growth.',
    paraTwo: 'Raindrop enables you to scale your Procurement growth needs by addressing –',
    subPonitOne: 'Regional and international growth',
    subPonitTwo: 'Employee growth',
    subPonitThree: 'Security and compliance requirements',
    HeadingThree: 'Functionality needed',
    HeadingThreeDen:
      'You’re looking for a solution to run all of your Procurement processes and cannot afford to buy multiple point solutions.',
    paraThree: 'Raindrop is a single system to perform your entire Procurement processes, including –',
    subPonitFour: 'Intake Orchestration',
    subPonitFive: 'Sourcing and Procurement',
    subPonitSix: 'Contract management',
    subPonitSeven: 'Purchase Orders',
    subPonitEight: 'Supplier onboarding',
    subPonitNine: 'Much more',
    icon: <FaArrowCircleRight />,
    paraData:
      'Quick to set up within minutes, get value to run your business immediately, easy to scale as you need more, and powerful functionality to run your small business.  This is Raindrop.',
  },
]
