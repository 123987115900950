import React from 'react'
import styled from 'styled-components'
import SolutionSmallEnterpriseTableData from '../Data/SolutionSmallEnterpriseTableData'
import { SmallTable } from './SmallTable'
import { ReUsePtag } from './ReUsePtag'

const AppSmallTableWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .IconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .paraStyle {
    padding: 5rem 5rem 0rem 5rem;
    @media (max-width: 1391px) {
      padding: 3rem 1rem 0rem 1rem;
    }
  }
`

export const AppSmallTable = () => {
  return (
    <AppSmallTableWapper>
      {SolutionSmallEnterpriseTableData.map((item, index) => {
        return (
          <div key={index}>
            <SmallTable
              Challenge={item.challengesPointOne}
              AddressPonit={item.challengesPointTwo}
              HeadingOne={item.HeadingOne}
              HeadingOneDen={item.HeadingOneDen}
              paraOne={item.paraOne}
              HeadingTwo={item.HeadingTwo}
              HeadingTwoDen={item.HeadingTwoDen}
              paraTwo={item.paraTwo}
              HeadingThree={item.HeadingThree}
              HeadingThreeDen={item.HeadingThreeDen}
              paraThree={item.paraThree}
              AddressIcon={item.icon}
              subPonitOne={item.subPonitOne}
              subPonitTwo={item.subPonitTwo}
              subPonitThree={item.subPonitThree}
              subPonitFour={item.subPonitFour}
              subPonitFive={item.subPonitFive}
              subPonitSix={item.subPonitSix}
              subPonitSeven={item.subPonitSeven}
              subPonitEight={item.subPonitEight}
              subPonitNine={item.subPonitNine}
              IconColor='IconColor'
            />
            <ReUsePtag para={item.paraData} paraStyle='paraStyle' />
          </div>
        )
      })}
    </AppSmallTableWapper>
  )
}
