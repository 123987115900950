import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    icon: <FaArrowCircleRight />,
    Challenge: 'Challenge',
    AddressPonit: 'How Raindrop Adds Value',
    HeadingOne: 'Procurement Services',
    HeadingOneDen:
      'Challenge with traditional procurement systems which are transactionally focused and minimally delivers on predictive and proactive capabilities.',
    paraOne: 'Raindrop enables the ability to bring your Procurement services to the next level by –',
    subParaOne: 'Machine Learning algorithms to predict savings',
    subParaTwo: 'Avoiding automatic and unknown contract renewals',
    subParaThree: 'Understand your Sourcing pipeline and help prioritize them.',
    HeadingTwo: 'System Challenge',
    HeadingTwoDen:
      ' Fragmented systems across the enterprise poses a challenge to gain a sourcing 360-degree view to execute on daily operations. ',
    paraTwo: 'Raindrop enables you to have a unified “Source-to-Contract” experience in a single platform by –',
    subPointFour: 'Seamlessly integrating your fragmented systems',
    subPonitFive: 'Providing a 360-degree view across your planning, spend, sourcing, and contracts',
    subPonitSix: 'Using Raindrop’s automation toolkit, you can recover time to do more strategic activities',

    HeadingThree: 'Adoption',
    HeadingThreeDen:
      'Traditional Procurement tools generally are not widely adopted among internal teams due to outdated user interfaces and limited collaborative features.',
    paraThree:
      'Raindrop was designed to primarily address the current system adoption barriers.  It provides an intuitive user interface and tools which –',
    subParaSeven: 'Increase collaboration between internal teams and suppliers',
    subParaEight:
      'Provide internal teams with a simplified view of their sourcing requests and who has the next action',
    subParaNine: 'Seamlessly integrate with your internal collaborative software',
  },
]
