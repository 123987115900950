import React from 'react'
import styled from 'styled-components'
import SolutionLagerBannerData from '../Data/SolutionLagerBannerData.json'
// import SolutionSmallEnterpriseTableData from '../Data/SolutionSmallEnterpriseTableData.json'
import { AppLagerTable } from './AppLagerTable'
import { Block } from './Block'
import { Button } from './Button'
import { LinkHubspot } from './LinkHubspot'

const AppLagerBannerWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  .LagerContain {
    max-width: 1920px;
    margin: auto;
  }
  .LagerSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .LagerHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .LagerHeadingBig {
    padding: 0px 0px 3% 10px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .LagerImage {
    height: auto;
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
  .LagerImageCenter {
    width: 75%;
    display: flex;
    float: right;
    @media (max-width: 1090px) {
      width: 100%;
      float: unset;
    }
  }
  .LagerParaSection {
    order: 0;
    margin-top: 5%;
    padding-left: 5rem;
    @media (max-width: 1920px) and (min-width: 1820px) {
      margin-top: 4%;
    }
    @media (max-width: 1090px) {
      order: 1;
      padding-left: unset;
    }
  }
  .LagerParaPonitSection {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .LagerPara {
    max-width: 825px;
    padding: 0px 0px 15px 10px;
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .LagerDownPara {
    max-width: 825px;
    padding: 2rem 0px 0px 10px;
    color: #047cc2;
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .LagerSmallParaOne {
    max-width: 825px;
    padding: 15px 0px 15px 10px;
    color: #047cc2;
    font-size: 1.5rem;
    font-weight: 600;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .LagerParaButton {
    max-width: auto;
    padding: 15px 100px 15px 10px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .LagerParaButtonOne {
    max-width: auto;
    padding: 25px 100px 15px 10px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }

  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .PonitPara {
    margin-top: 0%;
  }
  .buttonStyle {
    background: linear-gradient(90deg, #16b4ff, #644eff, #16b4ff);
    background-size: 400%;
    border-radius: 30px;
    border: none;
    padding: 0 20px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    /* margin: 3% 0% 3% 16%; */
    color: #fff;
    margin: 5% 0% 3% 0%;
    color: #fff;
    display: flex;
    margin: 2rem 0rem 5rem 17rem;
    @media (max-width: 1090px) {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
    @keyframes animate {
      0% {
        background-position: 0%;
      }
      100% {
        background-position: 400%;
      }
    }
  }
  .buttonStyle:hover {
    cursor: pointer;
    animation: animate 8s linear infinite;
    box-shadow: 0 8px 16px 0 (90deg, #16b4ff, #644eff, #16b4ff);

    .buttonStyle::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }
  .buttonStyle::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #16b4ff, #644eff, #16b4ff);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  .LagerDisplay {
    display: none;
  }
`

export const AppLagerBanner = () => {
  return (
    <AppLagerBannerWpper>
      {SolutionLagerBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              mainContain="LagerContain"
              containHeading="LagerHeadingSmall"
              containHeadingBig="LagerHeadingBig"
              containParaOne="LagerSmallParaOne"
              containParaFour="LagerDisplay"
              containParaFive="LagerDisplay"
              containParaTwo="LagerPara"
              containParaThree="LagerDownPara"
              InternalSection="LagerSection"
              containImage="LagerImage"
              altName={data.AltName}
              paraSection="LagerParaSection"
              allImage="LagerImageCenter"
            />
          </div>
        )
      })}
      <div>
        <AppLagerTable />
      </div>
      <div className="LagerParaPonitSection">
        <LinkHubspot to="https://share.hsforms.com/1wzfrY5DmSsa2666ecHtWvw4702y">
          <span className="sr-only">Jump to packages</span>
          <Button
            cta="Click here to get started."
            label="Click here to get started."
            type="submit"
            className="demobutton"
          />
        </LinkHubspot>
      </div>
    </AppLagerBannerWpper>
  )
}
