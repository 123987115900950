import React from 'react'
import styled from 'styled-components'
import { ReuseTable } from './ReuseTable'
import SolutionLargeData from '../Data/SolutionLargeData'

const AppLagerTableWapper = styled.div`
  .IconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .DisplayNone {
    display: none;
  }
  .subDis {
    padding: 50px 15px 15px 0px;
  }
`

export const AppLagerTable = () => {
  return (
    <AppLagerTableWapper>
      {SolutionLargeData.map((data, index) => {
        return (
          <div key={index}>
            <ReuseTable
              Challenge={data.Challenge}
              AddressPonit={data.AddressPonit}
              HeadingOne={data.HeadingOne}
              HeadingOneDen={data.HeadingOneDen}
              paraOne={data.paraOne}
              HeadingTwo={data.HeadingTwo}
              HeadingTwoDen={data.HeadingTwoDen}
              paraTwo={data.paraTwo}
              subParaFour={data.subPointFour}
              subParaOne={data.subParaOne}
              subParaTwo={data.subParaTwo}
              subParaThree={data.subParaThree}
              HeadingThree={data.HeadingThree}
              HeadingThreeDen={data.HeadingThreeDen}
              paraThree={data.paraThree}
              subParaFive={data.subPonitFive}
              subParaSix={data.subPonitSix}
              subParaSeven={data.subParaSeven}
              subParaEight={data.subParaEight}
              subParaNine={data.subParaNine}
              AddressIcon={data.icon}
              IconColorDisThree='IconColor'
              IconColorDisFour='IconColor'
              IconColorDisOne='IconColor'
              IconColorDisTwo='IconColor'
              IconColorDisFive='IconColor'
              IconColorDisSix='IconColor'
              IconColorDisSeven='IconColor'
              IconColorDisEight='IconColor'
              IconColorDisNine='IconColor'
              IconColorDisTen='DisplayNone'
            />
          </div>
        )
      })}
    </AppLagerTableWapper>
  )
}
