import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    Challenge: 'Challenge',
    AddressPonit: 'How Raindrop Adds Value',
    icon: <FaArrowCircleRight />,
    HeadingOne: 'Spend Visibility, Planning, and Operations',
    HeadingOneDen:
      'Your spend data may be fragmented across multiple systems and regions, and not providing the needed information for planning.',
    paraOne: 'Raindrop enables you to plan by providing a unified view into your global enterprise spend –',
    subParaOne: 'Across different regions, currency types, suppliers, commodities, and more.',
    subParaTwo:
      'For planning various scenarios such as mergers & acquisitions, annual operating plan, growth, and more.',
    subParaThree:
      'While delivering predictive capabilities through machine learning which identifies savings opportunities.',
    HeadingTwo: 'Cost of ownership',
    HeadingTwoDen:
      ' Traditional Procurement tools are complex, not easy to integrate to existing systems, and are labor intensive to implement and maintain.',
    paraTwo: 'With Raindrop, customers reduce their cost of ownership by –',
    subPointFour: 'Replacing and/or consolidating their legacy systems, resulting in lower systems spend.',
    subPonitFive: 'Using systems capabilities to lower their Procurement services delivery costs.',
    subPonitSix: 'Lowering the ongoing investment needed to care and feed the system.',

    HeadingThree: 'Spend leakage',
    HeadingThreeDen:
      'Traditional systems are transactionally focused and have potential for spend leakage between multiple systems used to fulfill business processes.',
    paraThree: 'Raindrop contains spend leakage through digitization capabilities which provide enterprises –',
    subParaSeven: 'Market intelligence on commodity acquisition cost',
    subParaEight: 'Notifications to avoid being surprised by automatic and unexpected contract renewals',
    subParaNine:
      'Insights to get cost relief from under-utilized commodities and contain costs prior to over-consumption.',
  },
]
