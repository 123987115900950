import React from 'react'
import styled from 'styled-components'
import { Banner } from './Banner'
import SolutionsBannerData from '../Data/SolutionsBannerData'

const AppSolutionsBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 96px 60px 96px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1390px) {
      padding: 80px 30px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .BannerSolutionSectionOne {
    margin-top: 1rem;
    @media (max-width: 1390px) {
      margin-top: 3rem;
    }
    @media (max-width: 1090px) {
      margin-top: auto;
    }
  }
  .SoltionBannerSectionTwo {
    height: auto;
    width: 40%;
    @media (max-width: 1390px) {
      height: auto;
      width: auto;
    }
  }
  .BannerSolutionsHeadingSize {
    max-width: 650px;
    text-align: left;
    font-size: 2.5rem;
    padding-bottom: 3%;
    line-height: 1.30435em;
  }
  .BannerSolutionsPara {
    max-width: 650px;
    padding: 10px 0px 0px 0px;
  }
  .BannerSolutionsImage {
    width: 100%;
    height: auto;
    @media (max-width: 1390px) {
      width: 80%;
      /* height: 500px; */
      margin-left: auto;
      margin-right: unset;
      display: flex;
    }
    @media (max-width: 1090px) {
      margin-top: 10%;
      width: 100%;
    }
  }
  .BannerSolutionLiStyle {
    text-decoration: none;
    list-style-type: none;
    padding: 1% 1% 1% 0%;
    word-break: unset;
  }
  .BannerSolutionUlStyle {
    display: grid;
    padding-left: unset;
    margin: -35px auto;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .displayNone {
    display: none;
  }
`

export const AppSolutionsBanner = () => {
  return (
    <AppSolutionsBannerWapper>
      {SolutionsBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Banner
              containSection="containSection"
              BannerHeading={data.Heading}
              paraOne={data.paraOne}
              ImagePath={data.ImagePath}
              LiIttemOne={data.LiPonitOne}
              LiIttemTwo={data.LiPonitTwo}
              // LiIttemThree={data.LiPonitThree}
              // LiIttemFour={data.LiPonitFour}
              Icon={data.icon}
              // IconLast={data.icon}
              IconThreetyle="displayNone"
              BannerSectionOne="BannerSolutionSectionOne"
              BannerSectionTwo="SoltionBannerSectionTwo"
              BannerHeadingSize="BannerSolutionsHeadingSize"
              BannerPara="BannerSolutionsPara"
              BannerImage="BannerSolutionsImage"
              altName={data.ALtName}
              UlStyle="BannerSolutionUlStyle"
              LiStyle="BannerSolutionLiStyle"
              IconOneStyle="iconColor"
              IconTwoStyle="iconColor"
              IconThreeStyle="displayNone"
              IconFourStyle="displayNone"
              IconFiveStyle="displayNone"
              scrollPathOne="ByCompanySize"
              offsetValue={-300}
              scrollPathTwo="ByRoles"
            />
          </div>
        )
      })}
    </AppSolutionsBannerWapper>
  )
}
