import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'

const SmallTableWapper = styled.div`
  padding-right: 5rem;
  padding-left: 5rem;
  max-width: 1920px;
  margin: auto;
  @media (max-width: 1321px) {
    padding-right: 10px;
    padding-left: 10px;
  }
  #Maindiv {
    border-radius: 32px 50px 32px;
    border: 2px solid #34a1d5;
    box-shadow: 0 0 20px 0px #9cdfff;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th {
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    background-color: #047cc2;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media (max-width: 1321px) {
      font-size: 1rem;
      text-align: left;
      padding-left: 2rem;
    }
  }
  td {
    text-align: left;
    padding: 1rem 2rem 1rem 2rem;
    width: 50%;
    border-bottom: none;
    border-top: 2px solid #34a1d5;
    border-left: none;
    border-right: none;
    @media (max-width: 1321px) {
      padding: 1rem 0rem 1rem 1rem;
    }
  }
  .headingColor {
    color: #047cc2;
    font-size: 1.2rem;
  }
  .IconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .SideBack {
    border-top-left-radius: 30px;
  }
  .rightCron {
    border-top-right-radius: 48px;
  }
`

export const SmallTable = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <SmallTableWapper>
      <div id='Maindiv'>
        <motion.table
          id={props.id}
          className={props.paraStyle}
          ref={ref}
          animate={controls}
          initial='hidden'
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 25 },
          }}
          transition={{ ease: 'easeOut', duration: 0.5, delay: 0.35 }}>
          <tbody>
            <tr>
              <th className='SideBack'>{props.Challenge}</th>
              <th className='rightCron'>{props.AddressPonit}</th>
            </tr>
            <tr>
              <td>
                <span className='headingColor'>{props.HeadingOne}</span>
                <span> - </span>
                <span>{props.HeadingOneDen}</span>
              </td>
              <td>{props.paraOne}</td>
            </tr>
            <tr>
              <td className='SideBack'>
                <span className='headingColor'>{props.HeadingTwo}</span>
                <span> - </span>
                <span>{props.HeadingTwoDen}</span>
              </td>
              <td>
                {props.paraTwo}
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitOne}</span>
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitTwo}</span>
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitThree}</span>
              </td>
            </tr>
            <tr>
              <td className='SideBack'>
                <span className='headingColor'>{props.HeadingThree}</span>
                <span> - </span>
                <span>{props.HeadingThreeDen}</span>
              </td>
              <td>
                {props.paraThree}
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitFour}</span>
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitFive}</span>
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitSix}</span>
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitSeven}</span>
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitEight}</span>
                <br />
                <br />
                <span className={props.IconColor}>{props.AddressIcon}</span>
                <span>{props.subPonitNine}</span>
              </td>
            </tr>
          </tbody>
        </motion.table>
      </div>
    </SmallTableWapper>
  )
}
