import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    icon: <FaArrowCircleRight />,
    Challenge: 'Challenge',
    AddressPonit: 'How Raindrop Adds Value',
    HeadingOne: 'Planning',
    HeadingOneDen:
      'Data scattered among multiple systems makes it difficult to accurately and effectively plan your organizational spend.',
    paraOne:
      'Raindrop enables you to have a unified spend data set for your financial planning needs by providing visibility into –',
    subParaOne: 'purchase commitments',
    subParaTwo: 'commodity market trends',
    subParaThree: 'spend by supplier, region, function, etc.',
    HeadingTwo: 'Controlling spend',
    HeadingTwoDen:
      'Rising commodity complexity and spend occurring across various functions all pose an inherent challenge which typically leads to increases in external spend.',
    paraTwo:
      'Raindrop provides the necessary tools and workflows needed to control your spend by creating calls to action –',
    subPointFour: 'when the allocated money is nearing full consumption',
    subPonitFive: 'when the commodity consumption is nearing the acquired amount',
    subPonitSix: 'when accruals exceed budgeted amount',

    HeadingThree: 'Analytics',
    HeadingThreeDen:
      'Lack of real-time visibility and spend transparency across different suppliers, commodities, regions, and functions.',
    paraThree: 'Raindrop provides a 360-degree view across different dimensions of spend, inclusive of –',
    subParaSeven: 'commodity acquisition patterns, savings and avoidance achieved',
    subParaEight: 'predicting opportunities and risk involved with external supplier spend',
    subParaNine:
      'predicting future trends using machine learning on your own spend data sets and historical market performance',
  },
]
